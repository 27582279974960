<template>
  <div>
    <v-list>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title>
            <slot name="title" />
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-divider></v-divider>
      <v-list-item>
        <v-list-item-content>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title v-text="'Status'" />
            </v-list-item-content>
            <v-list-item-content>
              <v-list-item-title>
                <v-chip v-text="status" :color="color">
                  <v-avatar left>
                    <v-icon>close</v-icon>
                  </v-avatar>
                </v-chip>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item v-if="marker.machine.Id">
            <v-list-item-content>
              <v-list-item-title v-text="'ID'" />
            </v-list-item-content>
            <v-list-item-content>
              <v-list-item-title v-text="marker.machine.Id" />
            </v-list-item-content>
          </v-list-item>
          <v-list-item v-if="marker.machine.HoursUsed">
            <v-list-item-content>
              <v-list-item-title v-text="categoryLabel" />
            </v-list-item-content>
            <v-list-item-content>
              <v-list-item-title v-text="`${marker.machine.HoursUsed} h`" />
            </v-list-item-content>
          </v-list-item>
          <v-list-item v-if="marker.machine.MilageKm">
            <v-list-item-content>
              <v-list-item-title v-text="'KM'" />
            </v-list-item-content>
            <v-list-item-content>
              <v-list-item-title v-text="`${marker.machine.MilageKm} km`" />
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title v-text="'Description'" />
            </v-list-item-content>
            <v-list-item-content>
              <v-list-item-title v-text="marker.machine.Description" />
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title v-text="'SerialNumber'" />
            </v-list-item-content>
            <v-list-item-content>
              <v-list-item-title v-text="marker.machine.SerialNumber" />
            </v-list-item-content>
          </v-list-item>
          <v-list-item v-if="marker.machine.Driver">
            <v-list-item-content>
              <v-list-item-title v-text="'Driver'" />
            </v-list-item-content>
            <v-list-item-content>
              <v-list-item-title v-text="marker.machine.Driver" />
            </v-list-item-content>
          </v-list-item>
          <v-list-item v-if="marker.machine.NBCompanyName">
            <v-list-item-content>
              <v-list-item-title v-text="'Företag'" />
            </v-list-item-content>
            <v-list-item-content>
              <v-list-item-title v-text="marker.machine.NBCompanyName" />
            </v-list-item-content>
          </v-list-item>
          <v-list-item v-if="marker.tracker">
            <v-list-item-content>
              <v-list-item-title v-text="'Position'" />
            </v-list-item-content>
            <v-list-item-content>
              <v-list-item-title>
                {{ marker.tracker.LastLocationTimestamp | dateFormat }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item v-if="marker.tracker">
            <v-list-item-content>
              <v-list-item-title v-text="'Latest update'" />
            </v-list-item-content>
            <v-list-item-content>
              <v-list-item-title>
                {{ marker.tracker.LastContact | dateFormat }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              <v-btn
                target="_blank"
                :href="
                  `https://web.smartdok.se/utstyr/Maskindetaljer.aspx?uid=${marker.machine.Id}&index=0`
                "
              >
                <v-icon small>launch</v-icon>
                <span class="btn-label">SmartDok</span>
              </v-btn>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-content>
      </v-list-item>
    </v-list>
    <v-img :src="image" :alt="marker.machine.Name" v-if="image" />
    <v-list v-if="marker.machine.Comment">
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title>
            <v-icon small>comment</v-icon>
            <span class="header-label">Comments</span>
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-divider></v-divider>
      <v-list-item>
        <v-list-item-content>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title v-text="marker.machine.Comment" />
            </v-list-item-content>
          </v-list-item>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </div>
</template>

<script>
export default {
  props: {
    marker: {
      type: Object,
      required: true
    }
  },

  computed: {
    status() {
      if (this.marker.tracker) {
        return this.AutomileStatus[this.marker.tracker.Status];
      } else {
        return "Untracked";
      }
    },

    image() {
      if (this.marker.tracker) {
        return this.marker.machine.ImageUrl
          ? this.marker.machine.ImageUrl
          : this.marker.tracker.ImageUrl;
      } else {
        return this.marker.machine.ImageUrl || null;
      }
    },

    categoryLabel() {
      switch (this.marker.machine.CategoryName) {
        case "Borr vagn":
          return "Slagverkstimmar";
        case "Borrvagn":
          return "Slagverkstimmar";
        case "Borrning, borrv B-serien + kärnborr":
          return "Slagverkstimmar";
        case "Grävmaskin":
          return "Grävningstimmar";
        case "Grävmaskin G-serien":
          return "Grävningstimmar";
        default:
          return 0;
      }
    },

    color() {
      const value = this.marker.tracker ? this.marker.tracker.Status : 99;

      switch (value) {
        case 0:
          return "#827717";
        case 1:
          return "#c62828";
        case 2:
          return "#388e3c";
        case 3:
          return "#37474f";
        case 4:
          return "#bdbdbd";
        case 5:
          return "#616161";
        default:
          return "#616161";
      }
    }
  },

  data: () => ({
    AutomileStatus: [
      "Standby",
      "Offline",
      "Online",
      "Sleeping",
      "ShutdownPending",
      "Shutdown"
    ]
  })
};
</script>

<style lang="sass" scoped>
.v-list-item
  &__title
    white-space: normal

.btn-label,
.header-label
  padding-left: .25rem
</style>
