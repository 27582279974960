import Vue from "vue";
import Vuex from "vuex";
import projects from "./modules/projects";
import items from "./modules/items";
import map from "./modules/map";

Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    user: {
      token: null,
      data: null
    }
  },

  getters: {
    hasReadPermissions: state =>
      state.user.data.idToken.roles?.includes("mp.read"),
    token: state => state.user.token?.accessToken,
    user: state => state.user
  },

  actions: {
    initialiseStore({ commit }) {
      commit("INIT_STORE");
    },

    clearStore({ commit }) {
      commit("CLEAR_STORE");
    },

    fetchUser({ commit }, user) {
      if (user) {
        commit("SET_USER", user);
      } else {
        commit("SET_USER", null);
      }
    },

    setToken({ commit }, token) {
      if (token) {
        commit("SET_TOKEN", token);
      } else {
        commit("SET_TOKEN", null);
      }
    }
  },

  mutations: {
    SET_USER(state, user) {
      state.user.data = user;
    },

    SET_TOKEN(state, token) {
      state.user.token = token;
    },

    INIT_STORE(state) {
      const sessionStore = sessionStorage.getItem("nbt_store");
      if (sessionStore) {
        this.replaceState(Object.assign(state, JSON.parse(sessionStore)));
      }
    },

    CLEAR_STORE() {
      sessionStorage.removeItem("nbt_store");
    }
  },

  modules: {
    items,
    map,
    projects
  }
});

store.subscribe((_mutation, state) => {
  sessionStorage.setItem("nbt_store", JSON.stringify(state));
});

export default store;
