<template>
  <v-list class="drawer__list" tag="ul" three-line v-if="items.length">
    <template v-for="item in searchedItems">
      <v-list-item
        v-if="filter.includes(item.machine.NBCompanyName)"
        class="drawer__list-item"
        tag="li"
        :key="item.machine.Id"
        @click="setSelected(item)"
      >
        <v-list-item-icon v-if="item.machine.CategoryIcon == 1">
          <div class="catergory-icons">
            <Icon name="categoryIcon1"></Icon>
          </div>
        </v-list-item-icon>
        <v-list-item-icon v-if="item.machine.CategoryIcon == 2">
          <div class="catergory-icons">
            <Icon name="categoryIcon2"></Icon>
          </div>
        </v-list-item-icon>
        <v-list-item-icon v-if="item.machine.CategoryIcon == 3">
          <div class="catergory-icons">
            <Icon name="categoryIcon3"></Icon>
          </div>
        </v-list-item-icon>
        <v-list-item-icon v-if="item.machine.CategoryIcon == 4">
          <div class="catergory-icons">
            <Icon name="categoryIcon4"></Icon>
          </div>
        </v-list-item-icon>
        <v-list-item-icon v-if="item.machine.CategoryIcon == 0">
          <div class="catergory-icons">
            <Icon name="categoryIcon0"></Icon>
          </div>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title v-html="item.machine.Name"></v-list-item-title>
          <v-list-item-subtitle v-html="getStatus(item)"></v-list-item-subtitle>
          <v-list-item-subtitle v-if="item.tracker">
            {{ item.tracker.LastLocationTimestamp | dateFormat }}
          </v-list-item-subtitle>
          <v-list-item-subtitle v-else v-html="'Unknown'" />
        </v-list-item-content>
      </v-list-item>
    </template>
  </v-list>
  <v-col v-else>
    <v-sheet>No items to show with current filter</v-sheet>
  </v-col>
</template>

<script>
import Icon from "../icons/Icons.vue";
import { mapGetters } from "vuex";

export default {
  components: {
    Icon
  },

  props: {
    search: String,
    items: {
      type: Array
    }
  },

  data: () => ({
    AutomileStatus: [
      "Standby",
      "Offline",
      "Online",
      "Sleeping",
      "ShutdownPending",
      "Shutdown"
    ]
  }),

  methods: {
    getStatus(item) {
      if (item.tracker) {
        return this.AutomileStatus[item.tracker.Status];
      } else {
        return "Untracked";
      }
    },

    setSelected(marker) {
      if (!marker) this.$emit("update", null);
      else this.$emit("update", { type: "item", marker });
    },

    getImageUrl(item) {
      if (item.tracker) {
        return item.machine.ImageUrl || item.tracker.ImageUrl;
      } else {
        return item.machine.ImageUrl || null;
      }
    }
  },

  computed: {
    ...mapGetters("map", {
      companiesVisibility: "getCompaniesVisibility"
    }),

    searchedItems: function() {
      return this.items.filter(item => {
        return item.machine.Name.toLowerCase().match(
          this.search.toLowerCase(),
          this.$root.$emit("showSearchedMachines", this.search)
        );
      });
    },

    filter: function() {
      let companyfilters = [];
      for (const [key, value] of Object.entries(this.companiesVisibility)) {
        if (value) companyfilters.push(key);
      }

      return companyfilters;
    }
  }
};
</script>

<style lang="sass" scoped>
.catergory-icons
  padding-top: 0.5rem
  margin-right: -1rem
  width: 2rem
  height: 2rem

.drawer
  &__list
    padding: 0 1.0rem
    //float: left

  &__list-item
    border-bottom: 1px solid #4c4c4c
</style>
