<template>
  <div class="map-container">
    <MglMap
      class="map-box"
      :center="center"
      :zoom="zoom"
      :accessToken="accessToken"
      :mapStyle.sync="mapStyle"
      @load="onMapLoaded"
      :cluster="true"
    >
      <div v-if="showProjects">
        <MglMarker
          @click="setSelected(marker, 'project')"
          :coordinates="getGeoLocation(marker.GeoLocation)"
          v-for="marker in filterByProjectCompany"
          :key="marker.id"
          color="#3bb2d0"
        >
          <Icon slot="marker" name="project"></Icon>
        </MglMarker>
      </div>
      <div v-if="showMachines">
        <MglMarker
          @click="setSelected(marker, 'item')"
          :coordinates="getLastLocation(marker.tracker)"
          v-for="marker in filterByMachineCompany"
          :key="marker.id"
          color="#32a852"
        >
          <div slot="marker" v-if="marker.machine.CategoryIcon == 1">
            <Icon name="categoryIcon1"></Icon>
          </div>
          <div slot="marker" v-if="marker.machine.CategoryIcon == 2">
            <Icon name="categoryIcon2"></Icon>
          </div>
          <div slot="marker" v-if="marker.machine.CategoryIcon == 3">
            <Icon name="categoryIcon3"></Icon>
          </div>
          <div slot="marker" v-if="marker.machine.CategoryIcon == 4">
            <Icon name="categoryIcon4"></Icon>
          </div>
          <div slot="marker" v-else><Icon name="unknown"></Icon></div>
        </MglMarker>
      </div>
      <div v-if="showMachines">
        <MglMarker
          @click="setSelected(marker, 'item')"
          :coordinates="getLastLocation(marker.tracker)"
          v-for="marker in filterByOfflineMachineCompany"
          :key="marker.id"
          color="#827717"
        >
          <div slot="marker" v-if="marker.machine.CategoryIcon == 1">
            <Icon name="categoryIcon1"></Icon>
          </div>
          <div slot="marker" v-if="marker.machine.CategoryIcon == 2">
            <Icon name="categoryIcon2"></Icon>
          </div>
          <div slot="marker" v-if="marker.machine.CategoryIcon == 3">
            <Icon name="categoryIcon3"></Icon>
          </div>
          <div slot="marker" v-if="marker.machine.CategoryIcon == 4">
            <Icon name="categoryIcon4"></Icon>
          </div>
          <div slot="marker" v-else><Icon name="unknown"></Icon></div>
        </MglMarker>
      </div>
    </MglMap>
  </div>
</template>

<script>
import Mapbox from "mapbox-gl";
import { MglMap, MglMarker } from "vue-mapbox";
import Icon from "../icons/MapIcons.vue";
import { mapGetters } from "vuex";

export default {
  components: {
    MglMap,
    MglMarker,
    Icon
  },

  props: {
    center: {
      type: Array
    },
    itemOnlineMarkers: {
      type: Array
    },
    itemOfflineMarkers: {
      type: Array
    },
    projectMarkers: {
      type: Array
    },
    zoom: {
      type: Number,
      default: 6
    }
  },

  data: () => ({
    accessToken:
      "pk.eyJ1IjoiY2hyaXN0b2ZlcnN0IiwiYSI6ImNrOTlvZnMxZzAycHQzb255dTVudG83YTIifQ.6vjEmJybh87yp0isj9262g",
    mapStyle: "mapbox://styles/christoferst/ck9dvlpsy1wyw1js5ubcqhcqg",
    projectSearch: "",
    machineSearch: "",
    filteredByNorr: "NorrbottensBergteknik",
    filteredByBohus: "Bohus",
    filteredByVestfold: "Vestfold"
  }),

  mounted() {
    this.$root.$on("showSearchedProjects", search => {
      this.projectSearch = search;
    });

    this.$root.$on("showSearchedMachines", search => {
      this.machineSearch = search;
    });

    // this.$root.$on("showCompanyNorrIcons", checkBoxNorr => {
    //   this.filteredByNorr = checkBoxNorr ? "NorrbottensBergteknik" : "";
    // });

    // this.$root.$on("showCompanyBohusIcons", checkBoxBohus => {
    //   this.filteredByBohus = checkBoxBohus ? "Bohus" : "";
    // });

    // this.$root.$on("showCompanyVestfoldIcons", checkBoxVestfold => {
    //   this.filteredByVestfold = checkBoxVestfold ? "Vestfold" : "";
    // });
  },

  methods: {
    setSelected(marker, type) {
      this.$emit("selected", { type, marker });
    },

    getGeoLocation(GeoLocation) {
      return [GeoLocation.Lon, GeoLocation.Lat];
    },

    getLastLocation(tracker) {
      return [tracker.LastLocation.Longitude, tracker.LastLocation.Latitude];
    },

    onMapLoaded(event) {
      this.map = event.map;
      this.$emit("mapLoaded");
    }
  },

  computed: {
    ...mapGetters("map", {
      showProjects: "getProjectsVisibility",
      showMachines: "getMachinesVisibility",
      companiesFilter: "getCompaniesVisibility"
    }),

    searchedProjects: function() {
      return this.projectMarkers.filter(project => {
        return project.ProjectName.toLowerCase().match(
          this.projectSearch.toLowerCase()
        );
      });
    },

    searchedMachines: function() {
      return this.itemOnlineMarkers.filter(item => {
        return item.machine.Name.toLowerCase().match(
          this.machineSearch.toLowerCase()
        );
      });
    },

    searchedOfflineMachines: function() {
      return this.itemOfflineMarkers.filter(item => {
        return item.machine.Name.toLowerCase().match(
          this.machineSearch.toLowerCase()
        );
      });
    },

    newFilter: function() {
      let companyfilters = [];
      for (const [key, value] of Object.entries(this.companiesFilter)) {
        if (value) companyfilters.push(key);
      }

      return companyfilters;
    },

    filterByProjectCompany: function() {
      const _filters = this.newFilter;
      return this.searchedProjects.filter(function(project) {
        return _filters.includes(project.NBCompanyName);
      });
    },

    filterByMachineCompany: function() {
      const _filters = this.newFilter;
      return this.searchedMachines.filter(function(item) {
        return _filters.includes(item.machine.NBCompanyName);
      });
    },

    filterByOfflineMachineCompany: function() {
      const _filters = this.newFilter;
      return this.searchedOfflineMachines.filter(function(item) {
        return _filters.includes(item.machine.NBCompanyName);
      });
    }
  },

  created() {
    this.mapbox = Mapbox;
  }
};
</script>

<style lang="sass" scoped>
.map-box
  height: calc(100vh - 75px)
</style>
