const state = {
  visibility: {
    projects: true,
    machines: true
  },
  companies: {
    NorrbottensBergteknik: true,
    Bohus: true,
    Vestfold: true
  },
  filters: []
};

// getters
const getters = {
  getProjectsVisibility: state => state.visibility.projects,
  getMachinesVisibility: state => state.visibility.machines,
  getCompaniesVisibility: state => state.companies,
  getItemsFilter: state => state.filters
};

// actions
const actions = {
  toggleProjects({ commit }) {
    commit("TOGGLE_PROJECT_VISIBILITY");
  },

  toggleMachines({ commit }) {
    commit("TOGGLE_MACHINE_VISIBILITY");
  },

  toggleCompany({ commit }, value) {
    commit("TOGGLE_COMPANY_VISIBILITY", value);
  },

  updateFilters({ commit }, value) {
    commit("UPDATE_ITEMS_FILTER", value);
  }
};

// mutations
const mutations = {
  TOGGLE_PROJECT_VISIBILITY(state) {
    state.visibility.projects = !state.visibility.projects;
  },

  TOGGLE_MACHINE_VISIBILITY(state) {
    state.visibility.machines = !state.visibility.machines;
  },

  TOGGLE_COMPANY_VISIBILITY(state, value) {
    state.companies[value] = !state.companies[value];
  },

  UPDATE_ITEMS_FILTER(state, value) {
    state.filters = value;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
