<template>
  <v-list>
    <v-list-item>
      <v-list-item-content>
        <v-list-item-title>
          <slot name="title" />
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>
    <v-divider></v-divider>
    <v-list-item>
      <v-list-item-content>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title v-text="'Company'" />
          </v-list-item-content>
          <v-list-item-content>
            <v-list-item-title v-text="marker.NBCompanyName" />
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title v-text="'Start date'" />
          </v-list-item-content>
          <v-list-item-content>
            <v-list-item-title v-if="marker.StartDate">
              {{ marker.StartDate | dateFormat }}
            </v-list-item-title>
            <v-list-item-title v-else v-text="'Unknown'" />
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title v-text="'End date'" />
          </v-list-item-content>
          <v-list-item-content>
            <v-list-item-title v-if="marker.EndDate">
              {{ marker.EndDate | dateFormat }}
            </v-list-item-title>
            <v-list-item-title v-else v-text="'Unknown'" />
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title v-text="'Location'" />
          </v-list-item-content>
          <v-list-item-content>
            <v-list-item-title
              v-text="marker.Location ? marker.Location : 'Unknown'"
            />
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title v-text="'Project owner'" />
          </v-list-item-content>
          <v-list-item-content>
            <v-list-item-title v-text="marker.ProjectOwnerName" />
          </v-list-item-content>
        </v-list-item>
        <v-list-item v-if="marker.DescriptionText">
          <v-list-item-content>
            <v-list-item-title v-text="'Description'" />
          </v-list-item-content>
          <v-list-item-content v-text="marker.DescriptionText" />
        </v-list-item>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title v-text="'Project number'" />
          </v-list-item-content>
          <v-list-item-content>
            <v-list-item-title v-text="marker.ProjectNumber" />
          </v-list-item-content>
        </v-list-item>
      </v-list-item-content>
    </v-list-item>
  </v-list>
</template>

<script>
export default {
  props: {
    marker: {
      type: Object,
      required: true
    }
  }
};
</script>

<style lang="sass" scoped>
.v-list-item
  &__title
    white-space: normal
</style>
