import Fuse from "fuse.js";

const options = {
  threshold: 0.2,
  includeMatches: true,
  minMatchCharLength: 3,
  keys: [
    "machine.Name",
    "machine.Driver",
    "machine.Description",
    "machine.SerialNumber"
  ]
};
const state = {
  all: [],
  loading: false,
  tracked: [],
  results: {
    list: [],
    online: [],
    offline: []
  }
};

// getters
const getters = {};

// actions
const actions = {
  async fetchItems({ state, commit }, { hostname, hasPermissions, token }) {
    const hasItems = state.all.length > 0;

    if (token && hasPermissions && !hasItems) {
      commit("SET_LOADING_STATE", true);

      try {
        const results = await fetch(hostname + "/api/items", {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token
          }
        });
        const items = await results.json();
        commit("SET_ALL_ITEMS", [...items.tracked, ...items.untracked]);
        commit("SET_TRACKED_ITEMS", items.tracked);
        commit("SET_LIST_RESULTS", [...items.tracked, ...items.untracked]);
        commit(
          "SET_ONLINE_RESULTS",
          items.tracked.filter(item => item.tracker.Status === 2)
        );
        commit(
          "SET_OFFLINE_RESULTS",
          items.tracked.filter(item => item.tracker.Status !== 2)
        );
      } catch (error) {
        console.error(error);
      } finally {
        commit("SET_LOADING_STATE", false);
      }
    }
  },

  filterItems({ commit, state }, term) {
    if (term.length >= 3) {
      const fuse = new Fuse(state.tracked, options);
      const allFuse = new Fuse(state.all, options);
      const results = fuse.search(term);
      const allResults = allFuse.search(term);
      const parsedResults = results.map(o => o.item);
      const parsedAllResults = allResults.map(o => o.item);

      if (parsedAllResults.length) {
        commit("SET_LIST_RESULTS", parsedAllResults);
      } else {
        commit("SET_LIST_RESULTS", []);
      }
      if (parsedResults.length) {
        commit(
          "SET_ONLINE_RESULTS",
          parsedResults.filter(item => item.tracker.Status === 2)
        );
        commit(
          "SET_OFFLINE_RESULTS",
          parsedResults.filter(item => item.tracker.Status !== 2)
        );
      } else {
        commit("SET_ONLINE_RESULTS", []);
        commit("SET_OFFLINE_RESULTS", []);
      }
    } else {
      commit("SET_LIST_RESULTS", state.all);
      commit(
        "SET_ONLINE_RESULTS",
        state.tracked.filter(item => item.tracker.Status === 2)
      );
      commit(
        "SET_OFFLINE_RESULTS",
        state.tracked.filter(item => item.tracker.Status !== 2)
      );
    }
  }
};

// mutations
const mutations = {
  SET_TRACKED_ITEMS(state, items) {
    state.tracked = items;
  },

  SET_ALL_ITEMS(state, items) {
    state.all = items;
  },

  SET_LIST_RESULTS(state, items) {
    state.results.list = items;
  },

  SET_ONLINE_RESULTS(state, items) {
    state.results.online = items;
  },

  SET_OFFLINE_RESULTS(state, items) {
    state.results.offline = items;
  },

  SET_LOADING_STATE(state, value) {
    state.loading = value;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
