<template>
  <div class="map-container">
    <v-navigation-drawer absolute temporary right v-model="drawer" width="375">
      <v-row class="drawer__header" dense>
        <v-col class="d-flex justify-end">
          <v-btn
            color="gray lighten-4"
            class="drawer__button"
            @click="toggleDrawer"
            icon
            small
          >
            <v-icon small>
              close
            </v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <div class="v-navigation-drawer__wrapper">
        <ProjectDetails
          :marker="selected.marker"
          v-if="selected && selected.type === 'project'"
        >
          <template slot="title">
            <h3>
              {{ selected.marker.ProjectName }}
            </h3>
          </template>
        </ProjectDetails>
        <ItemDetails
          :marker="selected.marker"
          v-if="selected && selected.type === 'item'"
        >
          <template slot="title">
            <h3>
              {{ selected.marker.machine.Name }}
            </h3>
          </template>
        </ItemDetails>
      </div>
    </v-navigation-drawer>
  </div>
</template>

<script>
import ProjectDetails from "./details-project";
import ItemDetails from "./details-item";

export default {
  components: {
    ItemDetails,
    ProjectDetails
  },

  data: () => ({
    drawer: false
  }),

  watch: {
    selected(val) {
      this.drawer = val ? true : false;
    }
  },

  props: {
    selected: {
      type: Object
    },

    visible: {
      type: Boolean
    }
  },

  computed: {
    status() {
      return this.visible;
    }
  },

  methods: {
    toggleDrawer() {
      this.$emit("selected", null);
    }
  }
};
</script>

<style lang="sass" scoped>
.drawer
  &__button
    margin: 0 .25rem
</style>
