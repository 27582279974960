<template>
  <div class="wrapper">
    <v-app id="inspire">
      <v-navigation-drawer v-model="drawer" app clipped>
        <v-list dense>
          <v-list-item
            v-for="item in items"
            :key="item.text"
            link
            :to="item.path"
          >
            <v-list-item-action>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>
                {{ item.text }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-navigation-drawer>

      <v-app-bar app clipped-left color="primary" dense class="nav-bar">
        <v-app-bar-nav-icon @click.stop="drawer = !drawer" />
        <v-toolbar-title class="mr-12 align-center">
          <span class="title">Nordisk Bergteknik</span>
        </v-toolbar-title>
        <v-spacer />
        <v-spacer />
        <div class="token d-flex align-center">
          <v-btn icon small @click="refresh" title="Click here get token">
            <v-icon small>refresh</v-icon>
          </v-btn>
        </div>
        <div v-if="user" class="profile d-flex align-center">
          <div class="profile__name">
            {{ user.name }}
          </div>
          <v-btn
            icon
            small
            @click="$msal.signOut"
            title="Click here to sign out"
          >
            <v-icon small>logout</v-icon>
          </v-btn>
        </div>
      </v-app-bar>

      <v-main>
        <v-container fluid fill-height>
          <v-layout>
            <v-flex grow>
              <div v-if="user">
                <router-view />
              </div>
              <div v-else>
                <v-card elevation="2" class="mx-auto my-14" max-width="440">
                  <v-card-title>
                    Please sign-in
                  </v-card-title>
                  <v-card-text>
                    To get access to the site, you need to sign in to your
                    account.
                  </v-card-text>
                  <v-card-actions>
                    <v-btn @click="signIn()">Sign in</v-btn>
                  </v-card-actions>
                </v-card>
              </div>
            </v-flex>
          </v-layout>
        </v-container>
      </v-main>
    </v-app>
  </div>
</template>

<script>
import { msalMixin } from "vue-msal";
import { mapActions } from "vuex";

export default {
  props: {
    source: String
  },

  mixins: [msalMixin],

  computed: {
    user() {
      let user = null;

      if (this.msal.isAuthenticated) {
        user = {
          ...this.msal.user,
          profile: {}
        };
        if (this.msal.graph && this.msal.graph.profile) {
          user.profile = this.msal.graph.profile;
        }
      }

      return user;
    }
  },

  data: () => ({
    drawer: false,
    items: [
      { icon: "assignment", text: "Projekt och Maskiner", path: "/" }
      // {
      //   icon: "info",
      //   text: "Om",
      //   path: "/about"
      // }
    ]
  }),

  created() {
    this.$vuetify.theme.dark = true;

    if (this.msal.isAuthenticated) {
      this.fetchUser(this.msal.user);

      this.$msal
        .acquireToken({
          scopes: [this.$config.aadScopes[0]]
        })
        .then(async tokenResponse => {
          this.setToken(tokenResponse);
        });
    }
  },

  methods: {
    ...mapActions(["fetchUser", "setToken"]),

    refresh() {
      this.$msal.acquireToken({
        scopes: [this.$config.aadScopes[0]]
      });
    },

    signIn() {
      this.removehash();
      this.$msal.signIn();
    },

    removehash() {
      history.replaceState("", document.title, window.location.pathname);
    }
  }
};
</script>

<style lang="sass" scoped>
@import "./styles/_variables.sass"
@import "./styles/_mixins.sass"

.wrapper
  position: relative
  top: 0
  bottom: 0
  right:0
  left: 0

.nav-bar
  position: absolute !important

.profile
  font-size: .875rem
  text-align: center

  &__name
    margin-right: .5rem
</style>
