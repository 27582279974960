<template>
  <div class="home">
    <ProjectsMap />
  </div>
</template>

<script>
import ProjectsMap from "@/components/Projects/projects-map.vue";

export default {
  components: {
    ProjectsMap
  }
};
</script>
