<template>
  <div class="drawer-container">
    <v-navigation-drawer absolute temporary right v-model="drawer" width="375">
      <template v-if="selected">
        <v-row class="drawer__header" dense>
          <v-col class="d-flex justify-end">
            <v-btn
              color="gray lighten-4"
              class="drawer__button"
              @click="setSelected(null)"
              small
            >
              <v-icon small>
                arrow_left
              </v-icon>
              Return to list
            </v-btn>
          </v-col>
        </v-row>
        <ProjectDetails
          :marker="selected.marker"
          v-if="selected.type === 'project'"
        >
          <template slot="title">
            <h3>
              {{ selected.marker.ProjectName }}
            </h3>
          </template>
        </ProjectDetails>
        <ItemDetails
          :marker="selected.marker"
          v-else-if="selected.type === 'item'"
        >
          <template slot="title">
            <h3>
              {{ selected.marker.machine.Name }}
            </h3>
          </template>
        </ItemDetails>
      </template>
      <template v-else>
        <v-list class="filterSorting">
          <v-list-group>
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title>Filter</v-list-item-title>
              </v-list-item-content>
            </template>
            <v-list-item-group multiple>
              <v-subheader class="subheader">
                Visa endast
              </v-subheader>
              <v-list-item>
                <v-checkbox
                  class="search-box__checkbox"
                  color="#3bb2d0"
                  dense
                  hide-details="true"
                  label="Projekt"
                  :input-value="showProjects"
                  @change="toggleProjects"
                />
              </v-list-item>
              <v-list-item>
                <v-checkbox
                  class="search-box__checkbox"
                  color="#32a852"
                  dense
                  hide-details="true"
                  label="Maskiner"
                  :input-value="showMachines"
                  @change="toggleMachines"
                />
              </v-list-item>
            </v-list-item-group>
            <v-list-item-group multiple>
              <v-subheader class="subheader">
                Bolag
              </v-subheader>
              <v-list-item>
                <v-checkbox
                  class="search-box__checkbox"
                  dense
                  hide-details="true"
                  label="Norrbottens Bergteknik"
                  :input-value="checkBoxNorr"
                  @change="toggleCompanyVisibility('NorrbottensBergteknik')"
                />
              </v-list-item>
              <v-list-item>
                <v-checkbox
                  class="search-box__checkbox"
                  dense
                  hide-details="true"
                  label="Bohus"
                  :input-value="checkBoxBohus"
                  @change="toggleCompanyVisibility('Bohus')"
                />
              </v-list-item>
              <v-list-item>
                <v-checkbox
                  class="search-box__checkbox"
                  dense
                  hide-details="true"
                  label="Vestfold"
                  :input-value="checkBoxVestfold"
                  @change="toggleCompanyVisibility('Vestfold')"
                />
              </v-list-item>
            </v-list-item-group>
            <v-list-item-group>
              <v-subheader class="subheader">
                Fritextsök
              </v-subheader>
              <v-list-item>
                <input
                  class="searchbar"
                  type="text"
                  v-model="search"
                  placeholder="Sök projektnamn, maskin..."
                />
              </v-list-item>
            </v-list-item-group>
          </v-list-group>
        </v-list>
        <ProjectsList
          v-if="showProjects"
          @update="setSelected"
          :projects="projects"
          :search="search"
        />
        <ItemsList
          v-if="showMachines"
          @update="setSelected"
          :items="items"
          :search="search"
        />
      </template>
    </v-navigation-drawer>
    <v-btn
      color="indigo"
      class="toggle-list"
      @click="drawer = true"
      small
      absolute
      fab
      right
    >
      <v-icon small>
        assignment
      </v-icon>
    </v-btn>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

import ProjectDetails from "./details-project";
import ItemDetails from "./details-item";
import ItemsList from "./filter-machines";
import ProjectsList from "./filter-projects";

export default {
  components: {
    ItemDetails,
    ItemsList,
    ProjectDetails,
    ProjectsList
  },

  props: {
    items: {
      type: Array
    },
    projects: {
      type: Array
    }
  },

  data: function() {
    return {
      search: "",
      showFilters: false,
      showSorting: false,
      tab: null,
      drawer: null,
      selected: null,
      checkBoxNorr: true,
      checkBoxBohus: true,
      checkBoxVestfold: true
    };
  },

  methods: {
    ...mapActions("map", ["toggleMachines", "toggleProjects", "toggleCompany"]),

    setSelected(obj) {
      if (obj) {
        this.selected = {
          type: obj.type,
          marker: obj.marker
        };
      } else {
        this.selected = null;
      }
    },

    toggleDrawer() {
      this.drawer = !this.drawer;
    },

    toggleFilter() {
      if (this.showFilters == false)
        (this.showFilters = true), (this.showSorting = false);
      else if (this.showFilters == true) this.showFilters = false;
      this.showSorting = false;
    },

    toggleSorting() {
      if (this.showSorting == false)
        (this.showSorting = true), (this.showFilters = false);
      else this.showSorting = false;
    },

    toggleCompanyVisibility(value) {
      this.toggleCompany(value);
    }
  },
  computed: {
    ...mapGetters("map", {
      showMachines: "getMachinesVisibility",
      showProjects: "getProjectsVisibility"
    })
  }
};
</script>

<style lang="sass" scoped>
.subheader
  align-items: flex-end

.searchbar
  width: 14.0rem
  border-bottom: solid 1px white
  font-size: 1.0rem

::placeholder
  color: rgba(255, 255, 255, 0.7)
  opacity: 1

input:focus
  outline: none

.toggle-list
  top: 1.0rem

.drawer-close
  margin: 0.55rem
  right: 0.6rem
  top: 0rem
  position: absolute
  float: right
  z-index: 1

.drawer
  &__button
    margin: 0 .25rem

.filterSorting
  border-bottom: 1px solid #4c4c4c
  margin-bottom: 1rem

.filter
  font-family: "Roboto", sans-serif
  font-size: 1.0rem
  font-weight: 400
  line-height: 3rem
  letter-spacing: 0.1rem
  padding-left: 2.5rem
  cursor: pointer

.filter-list
  margin-top: 0.5rem
  margin-bottom: 2rem

.filter-list > li
  list-style-type: none
  padding-left: 1rem

.filter-list > li > input
  margin-right: 0.5rem
  font-weight: 300

.filter-list > li > span
  font-size: 1rem
  font-weight: 300

.arrowSpan
  position: relative
  top: -1px
  padding-left: 0.8rem
  cursor: pointer

.arrow
  border: solid white
  border-width: 0 2px 2px 0
  display: inline-block
  padding: 0.2rem

.down
  transform: rotate(45deg)
  -webkit-transform: rotate(45deg)

.up
  transform: rotate(-135deg)
  -webkit-transform: rotate(-135deg)

//Checkbox
.checkboxContainer
  display: block
  position: relative
  padding-left: 28px
  margin-bottom: 5px
  margin-top: 5px
  cursor: pointer
  font-size: 15px
  font-weight: 200
  -webkit-user-select: none
  -moz-user-select: none
  -ms-user-select: none
  user-select: none

/* Hide the browser's default checkbox */
.checkboxContainer input
  position: absolute
  opacity: 0
  cursor: pointer
  height: 0
  width: 0

/* Create a custom checkbox */
.checkmark
  position: absolute
  top: 3px
  left: 0
  height: 15px
  width: 15px
  background-color: #eee

/* On mouse-over, add a grey background color */
.checkboxContainer:hover input ~ .checkmark
  background-color: #ccc

/* When the checkbox is checked, add a blue background */
.checkboxContainer input:checked ~ .checkmark
  background-color: black

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after
  content: ""
  position: absolute
  display: none

/* Show the checkmark when checked */
.checkboxContainer input:checked ~ .checkmark:after
  display: block

/* Style the checkmark/indicator */
.checkboxContainer .checkmark:after
  left: 4px
  top: 1px
  width: 7px
  height: 10px
  border: solid white
  border-width: 0 2px 2px 0
  -webkit-transform: rotate(45deg)
  -ms-transform: rotate(45deg)
  transform: rotate(45deg)
</style>
