import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import "./plugins/filters";
import msal from "vue-msal";
import "material-design-icons-iconfont/dist/material-design-icons.css";

Vue.config.productionTip = false;

fetch(process.env.BASE_URL + "config.json")
  .then(response => response.json())
  .then(config => {
    Vue.use(msal, {
      auth: {
        clientId: config["aadClientId"],
        tenantId: config["aadAuthority"],
        redirectUri: window.location.origin,
        beforeSignOut: () => {
          sessionStorage.removeItem("nbt_store");
        }
      }
    });

    Vue.prototype.$config = config;
    new Vue({
      store,
      router,
      beforeCreate() {
        this.$store.commit("INIT_STORE");
      },
      vuetify,
      render: h => h(App)
    }).$mount("#app");
  });
