<template>
  <div>
    <Unknown v-if="name === 'unknown'" />
    <DrillingRig v-if="name === 'categoryIcon1'" />
    <Excavator v-if="name === 'categoryIcon2'" />
    <Vehicle v-if="name === 'categoryIcon3'" />
    <Project v-if="name === 'project'" />
    <Equipment v-if="name === 'categoryIcon4'" />
  </div>
</template>

<script>
import DrillingRig from "./drilling-rig";
import Equipment from "./equipment";
import Excavator from "./excavator";
import Project from "./project";
import Vehicle from "./vehicle";
import Unknown from "./unknown";

export default {
  components: { DrillingRig, Equipment, Excavator, Project, Vehicle, Unknown },
  props: ["name"]
};
</script>
