<template>
  <v-list class="drawer__list" tag="ul" three-line v-if="projects.length">
    <template v-for="project in searchedProjects">
      <v-list-item
        v-if="filter.includes(project.NBCompanyName)"
        class="drawer__list-item"
        tag="li"
        :key="project.Id"
        @click="setSelected(project)"
      >
        <v-list-item-content>
          <div class="icon">
            <v-list-item-icon>
              <div class="catergory-icons">
                <Icon name="project"></Icon>
              </div>
            </v-list-item-icon>
          </div>
          <v-list-item-title v-html="project.ProjectName"></v-list-item-title>
          <v-list-item-subtitle v-html="project.NBCompanyName" />
          <v-list-item-subtitle v-html="project.Location" />
        </v-list-item-content>
      </v-list-item>
    </template>
  </v-list>
</template>

<script>
import Icon from "../icons/Icons.vue";
import { mapGetters } from "vuex";

export default {
  components: {
    Icon
  },

  props: {
    search: String,
    projects: {
      type: Array
    }
  },

  methods: {
    getStatus(id) {
      return this.AutomileStatus[id];
    },

    setSelected(marker) {
      this.$emit("update", { type: "project", marker });
    }
  },

  computed: {
    ...mapGetters("map", {
      companiesVisibility: "getCompaniesVisibility"
    }),

    searchedProjects: function() {
      return this.projects.filter(project => {
        return project.ProjectName.toLowerCase().match(
          this.search.toLowerCase(),
          this.$root.$emit("showSearchedProjects", this.search)
        );
      });
    },

    filter: function() {
      let companyfilters = [];
      for (const [key, value] of Object.entries(this.companiesVisibility)) {
        if (value) companyfilters.push(key);
      }

      return companyfilters;
    }
  }
};
</script>

<style lang="sass" scoped>
.icon
  float: left
  margin-top: -1.3rem

.catergory-icons
  padding-top: .8rem
  margin-right: -1.0rem
  width: 2.0rem
  height: 2.0rem

.v-list-item__content
  display: block !important
  margin-top: 0.3rem

.drawer
  &__list
    margin: 0
    padding: 0

  &__list-item
    border-bottom: 1px solid #4c4c4c
</style>
