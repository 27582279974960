<template>
  <div v-if="msal.isAuthenticated && token">
    <div class="projects-map" v-if="hasReadPermissions">
      <v-card tile class="search-box">
        <v-card-title>
          <v-text-field
            class="search-box__field"
            hide-details="true"
            label="Sök..."
            @input="debounceSearch"
            v-model="searchQuery"
          />
        </v-card-title>
        <v-card-text class="d-flex justify-space-between align-end">
          <v-sheet>Visa: </v-sheet>
          <v-checkbox
            class="search-box__checkbox"
            color="#3bb2d0"
            dense
            hide-details="true"
            label="Projekt"
            :input-value="showProjectMarkers"
            @change="toggleProjects"
          />
          <v-checkbox
            class="search-box__checkbox"
            color="#32a852"
            dense
            hide-details="true"
            label="Maskiner"
            :input-value="showMachineMarkers"
            @change="toggleMachines"
          />
        </v-card-text>
      </v-card>
      <VueMap
        :zoom="zoom"
        :center="center"
        :project-markers="showProjects ? projectMarkers : []"
        :item-online-markers="showMachines ? itemMarkers.online : []"
        :item-offline-markers="showMachines ? itemMarkers.offline : []"
        @selected="itemSelected"
        @mapLoaded="fetch"
      />
      <DetailsDrawer
        :selected="selectedItem"
        @selected="itemSelected"
        :visible="selectedItem ? true : false"
      />
      <ListDrawer
        :items="showMachines ? itemMarkers.list : []"
        :projects="showProjects ? projectMarkers : []"
        @selected="itemSelected"
      />
      <v-overlay :value="itemsLoading || projectsLoading" absolute>
        <v-progress-circular indeterminate size="64" />
      </v-overlay>
    </div>
    <div v-else>
      <v-card elevation="2" class="mx-auto my-14" max-width="440">
        <v-card-title>
          Missing permissions
        </v-card-title>
        <v-card-text>
          Your current account doesn't have "read" permissions from the projects
          database and therefore cannot render the map. <br /><br />Contact the
          administrator to get sufficient permissions or logout and use a
          different account.
        </v-card-text>
        <v-card-actions>
          <v-btn @click="$msal.signOut" title="Click here to sign out">
            Sign out
          </v-btn>
        </v-card-actions>
      </v-card>
    </div>
  </div>
</template>

<script>
import VueMap from "@/components/shared/map.vue";
import ListDrawer from "@/components/shared/list-drawer.vue";
import DetailsDrawer from "@/components/shared/details-drawer.vue";
import { mapActions, mapGetters, mapState } from "vuex";
import { msalMixin } from "vue-msal";

export default {
  mixins: [msalMixin],

  components: {
    DetailsDrawer,
    ListDrawer,
    VueMap
  },

  data: () => ({
    debounce: null,
    center: [14.689, 62.969],
    searchQuery: null,
    selectedItem: null,
    renderMap: false,
    zoom: 4.36
  }),

  methods: {
    ...mapActions("projects", ["fetchProjects", "filterProjects"]),
    ...mapActions("items", ["fetchItems", "filterItems"]),
    ...mapActions("map", ["toggleMachines", "toggleProjects"]),

    getGeoLocation(GeoLocation) {
      return [GeoLocation.Lon, GeoLocation.Lat];
    },

    fetch() {
      if (this.msal.isAuthenticated) {
        this.fetchProjects({
          hostname: this.$config["apiHostname"],
          hasPermissions: this.hasReadPermissions,
          token: this.token
        });
        this.fetchItems({
          hostname: this.$config["apiHostname"],
          hasPermissions: this.hasReadPermissions,
          token: this.token
        });
      }
    },

    search(term) {
      this.filterProjects(term);
      this.filterItems(term);
    },

    debounceSearch(term) {
      clearTimeout(this.debounce);
      this.debounce = setTimeout(() => {
        this.search(term);
      }, 600);
    },

    itemSelected(obj) {
      this.selectedItem = obj;
    }
  },

  computed: {
    ...mapState({
      projectsLoading: state => state.projects.loading,
      projectMarkers: state => state.projects.results,
      all: state => state.items.all,
      itemMarkers: state => state.items.results,
      itemsLoading: state => state.items.loading
    }),

    ...mapGetters("projects", {
      filteredProjects: "filteredProjects"
    }),

    ...mapGetters({
      hasReadPermissions: "hasReadPermissions",
      token: "token",
      user: "user"
    }),

    ...mapGetters("map", {
      showMachines: "getMachinesVisibility",
      showProjects: "getProjectsVisibility"
    }),

    showProjectMarkers: function() {
      return this.showProjects ? this.showProjects : false;
    },

    showMachineMarkers: function() {
      return this.showMachines ? this.showMachines : false;
    }
  }
};
</script>

<style lang="sass" scoped>
@import "../../styles/_variables.sass"
@import "../../styles/_mixins.sass"

.search-box
  +breakpoint($bp-small)
    position: absolute
    width: 20.0rem
    z-index: 5
    display: initial

  &__field
    margin: 0
    padding-top: .25rem

  &__checkbox
    margin: 0
</style>
