import Fuse from "fuse.js";

const options = {
  threshold: 0.2,
  minMatchCharLength: 3,
  includeMatches: true,
  keys: [
    "ProjectName",
    "NBCompanyName",
    "Location",
    "ProjectOwnerName",
    "ProjectNumber"
  ]
};
const state = {
  all: [],
  loading: false,
  results: []
};

// getters
const getters = {
  projects: state => state.projects
};

// actions
const actions = {
  async fetchProjects({ state, commit }, { hostname, hasPermissions, token }) {
    const hasProjects = state.all.length > 0;
    if (token && hasPermissions && !hasProjects) {
      commit("SET_LOADING_STATE", true);
      try {
        const results = await fetch(hostname + "/api/projects", {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token
          }
        });

        const _projects = await results.json();
        const projects = _projects.filter(p => p.GeoLocation);

        commit("SET_PROJECTS", projects);
        commit("SET_RESULTS", projects);
      } catch (error) {
        console.error(error);
      } finally {
        commit("SET_LOADING_STATE", false);
      }
    }
  },

  filterProjects({ commit, state }, term) {
    if (term.length >= 3) {
      const fuse = new Fuse(state.all, options);
      const results = fuse.search(term);
      commit(
        "SET_RESULTS",
        results.map(o => o.item)
      );
    } else {
      commit("SET_RESULTS", state.all);
    }
  }
};

// mutations
const mutations = {
  SET_PROJECTS(state, projects) {
    state.all = projects;
  },

  SET_RESULTS(state, results) {
    state.results = results;
  },

  SET_LOADING_STATE(state, value) {
    state.loading = value;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
